#root {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  height: 100%;
}

#content {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $visyn-header-height);
  width: 100%;
  overflow: hidden;

  &.ordino-main-with-banner {
    height: calc(100vh - #{$visyn-header-height} - #{$banner-height});
    margin-top: $banner-height;
  }
}

.ordino-filmstrip {
  width: fit-content;
  height: 100%;
  min-height: 0px;
  position: relative;
  display: flex;
  overflow-x: visible;
  transition: transform 0.6s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  transition-delay: 0.7s;
  overflow-y: hidden;
}

.ordino-workbench {
  z-index: 10;
  width: $workbench-width;
  position: relative;
  transition: width 0.6s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  overflow: hidden;

  &.start.t-focus {
    width: $workbench-width;
  }

  &:not(:only-child) {
    border-right: none;
  }

  &.transition.t-context {
    border-right: 2px solid $gray-700;
    width: $workbench-context-width;
  }

  &.transition.t-focus {
    width: 50vw;
    border-right: 2px solid $gray-700;
  }

  &.transition.t-next {
    width: 50vw;
  }
  &.transition.t-previous {
    width: $workbench-context-width;
  }

  &.t-focus {
    width: $workbench-focus-width;
  }

  &.t-context:not(.collapsed) {
    border-right: 2px solid $gray-700;
    width: $workbench-context-width;
  }

  &.t-context.collapsed + .t-focus:not(.transition) {
    width: $workbench-width;
  }

  &.t-context.collapsed:not(.transition) {
    width: 0;
  }
  &.t-next {
    width: $workbench-focus-width;
  }
  &.t-previous {
    width: $workbench-context-width;
  }
}

/**
 * Reset the overflow for the LineUp filter side panel.
 * The LineUp library sets it to `overflow: auto` which causes the filter panel to be hidden.
 * For us the side panel can be fully visible because we wrapped it in the scroll area.
 */
.lu-side-panel-ranking-main {
  overflow: unset;
}
