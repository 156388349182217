// 2. Include any default variable overrides here
// @see https://getbootstrap.com/docs/5.1/customize/sass/#importing
$primary: #337AB7 !default;
$light: #F1F2F4 !default;
$dark: #2F353A !default;

// Scale down to 90% of default font-size
// @see https://getbootstrap.com/docs/5.1/content/typography/#global-settings
$font-size-base: 0.9rem !default;

// Override spacing (Bootstrap default: 1 rem)
// @see https://getbootstrap.com/docs/5.1/utilities/spacing/
$spacer: 1.25rem !default;

// This will be merged in file _3_variables_mixins_utilities.scss with the bootstrap spacers
$phovea-spacers: (
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
  8: ($spacer * 5),
  9: ($spacer * 6),
  10: ($spacer * 7)
) !default;


$phovea-theme-colors: () !default;