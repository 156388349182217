$select-color: orange !default;
$select-color-bright: #ffdca3 !default;
$hover-color: black !default;

$hover-presentation-color: #f0bb00 !default;

$core_colour: #f0f0f0 !default;
$core_colour_text: #333 !default;
$core_colour_selected: #ddd !default;
$core_border: #ddd !default;

$exploration-stage: $core_colour !default;
$exploration-stage-hover: $core_colour_selected !default;

$authoring-stage: $core_colour !default;
$authoring-stage-hover: $core_colour_selected !default;
$presentation-stage: $core_colour !default;
$presentation-stage-hover: $core_colour_selected !default;

$core_player: $authoring-stage !default;
$core_duration: #D8D8D8 !default;

$header-menu-bg-color: #ddd !default;
$header-menu-color: #333 !default;

$core_color_almost_black: black !default;

$annotation_color: #009ACD !default;
$annotation_color_hover: #006b90 !default;

$anchor_color: #EE2329 !default;
$anchor_color_hover: shade-color($anchor_color, 20%) !default;

$select-data-color: orange !default;
$hover-data-color: #ffc46b !default;

$tdp-css-prefix: 'tdp' !default;

$tdp-view-wrapper-preview-background: white !default;

/* phovea_clue */
$select-color: orange !default;
$select-color-bright: #ffdca3 !default;
$hover-color: black !default;

$hover-presentation-color: #f0bb00 !default;

$core_colour: #60AA85 !default;
$core_colour_text: #F5F3F3 !default;
$core_colour_selected:#72C29B !default;
$core_border : $core_colour !default;

$exploration-stage: $core_colour !default;
$exploration-stage-hover: $core_colour_selected !default;

$authoring-stage: $core_colour !default;
$authoring-stage-hover: $core_colour_selected !default;
$presentation-stage: $core_colour !default;
$presentation-stage-hover: $core_colour_selected !default;

$core_player: $authoring-stage !default;
$core_duration:#D8D8D8 !default;


$header-menu-bg-color: rgb(190, 218, 194) !default;
$header-menu-color: #333 !default;

$core_color_almost_black: black !default;

$annotation_color: #009ACD !default;
$annotation_color_hover: #006b90 !default;

$anchor_color: red !default;
$anchor_color_hover: darkred !default;

$phovea-loading-icon-url: url('../../assets/caleydo_c_anim.svg') !default;
$phovea-loading-icon-size: auto !default;

$phovea-navbar-icon-url: url('../../assets/caleydo_c.svg') !default;
$phovea-navbar-icon-width: 20px !default;
$phovea-navbar-icon-top: center !default;

$phovea-about-dialog-icon-url: url('../../assets/caleydo_c.svg') !default;
$phovea-about-dialog-icon-height: unset !default;

$select-color: orange !default;
$select-color-bright: #ffdca3 !default;
$hover-color: black !default;

$hover-presentation-color: #f0bb00 !default;

$annotation_color: #009ACD !default;
$annotation_color_hover: #006b90 !default;

$anchor_color: red !default;
$anchor_color_hover: darkred !default;

$select-data-color: orange !default;
$hover-data-color: #ffc46b !default;

