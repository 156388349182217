// Modify the Boostrap SASS maps before importing the components
// @see https://getbootstrap.com/docs/5.0/customize/sass/#maps-and-loops

$ordino-white-hover: #ffffffbf !default; // add some alpha on hover

$ordino-grid-breakpoints: (
  xxxl: 1800px // additional breakpoint to hide scrollspy nav,
) !default;

$grid-breakpoints: map-merge($grid-breakpoints, $ordino-grid-breakpoints);

// overwrite the blue color of the chevron in the accordion
$accordion-button-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c757d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
$accordion-button-icon: $accordion-button-active-icon;
$accordion-button-color: black;
$accordion-button-active-color: black;
$accordion-button-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: none;
$accordion-border-color:   transparent;