// Variables
@import './abstracts/variables';

/// Vendors
@import './vendors/react-mosaic';
@import 'mantine-react-table/styles.css';

// Base
@import './base/base';

/// Components
@import './components/StartMenu';
@import './components/OrdinoScrollspy';
