body,
html {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

th,
label,
strong,
b,
.label {
  font-weight: 500 !important;
}

body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

* {
  //print precisely
  -webkit-print-color-adjust: exact;
}

main {
  position: relative;

  -webkit-flex: 1 1 auto; /* Chrome */
  flex: 1 1 auto;
}