// With https://github.com/lineupjs/lineupengine/releases/tag/v2.5.3, they changed from @import to @use, forcing us to use the variables before being able to override them.
@use 'lineupengine/src/styles/vars' as * with (
  // TODO: Without this, we get "Can't resolve './node_modules/lineupengine/src/styles/lineupengine/src/assets/loading.svg' in '.../workspaces/<repo>'"
  // Why do I need to do this? Probably because lineupjs defines $engine_assets and it is resolving it relatively?
  $engine_assets: '../assets',
  $engine_loading_image: url('../assets/loading.svg'),
  $engine_loading_static_image: url('../assets/loading_s.svg'),
  $engine_highlight_mode: 'background',
  $engine_row_outline_color: #ffe8be,
  $engine_row_background_color: #ffe8be
);

@use 'lineupjs/src/styles/vars' as * with (
  $lu_assets: '../assets',
  $lu_use_font_awesome: true,
  // do not include Font Awesome files from LineUp, but use ours instead
  $lu_include_font_awesome: false,
  $lu_dialog_font_size: 0.9rem,
  $lu_toolbar_font_size: 0.9rem
);

@import 'lineupjs/src/styles/lineup.scss';

.lu {
  // use a fixed font-size for the ranking + side panel with the Roboto font family.
  // this font-size corresponds with the visual size of the previous font family Yantramanav
  // and allows to display more rows by default.
  // the LineUp zoom buttons are still working as they are using percentages (e.g., `font-size: 100%`)
  // which works relative to the font size defined here.
  font-size: 13.4px;
  // unset to reset the LineUp default line height and set it to the Bootstrap default line height
  line-height: unset;
}

// hide the column type icons from the header
.lu-typed-icon::before {
  display: none !important;
}

.lu-side-panel .lu-stats {
  display: none;
}

// override lineup sort icons
// sort neutral
.lu-action-sort::before {
  background-color: currentColor;
  content: '11' !important;  /* This is necessary for the icons to have the same size */
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='512' height='472' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.20093 110.314C-2.73364 121.248 -2.73364 138.977 8.20093 149.912C19.1355 160.846 36.864 160.846 47.7986 149.912L101.402 96.308V443.711C101.402 459.175 113.938 471.711 129.402 471.711C144.866 471.711 157.402 459.175 157.402 443.711V96.2896L211.024 149.912C221.959 160.846 239.687 160.846 250.622 149.912C261.556 138.977 261.556 121.248 250.622 110.314L153.246 12.9376C152.213 11.3649 151.005 9.87317 149.622 8.49023C144.05 2.91797 136.713 0.185425 129.41 0.292358C122.108 0.185913 114.772 2.91858 109.201 8.49036C107.818 9.87317 106.61 11.3646 105.577 12.9373L8.20093 110.314ZM354.579 375.692V28.2893C354.579 12.8253 367.114 0.289307 382.579 0.289307C398.043 0.289307 410.579 12.8253 410.579 28.2893V375.71L464.201 322.088C475.135 311.154 492.864 311.154 503.799 322.088C514.733 333.023 514.733 350.752 503.799 361.686L406.423 459.062C405.389 460.635 404.181 462.127 402.799 463.51C397.226 469.082 389.889 471.815 382.587 471.708C375.285 471.814 367.949 469.081 362.378 463.51C360.995 462.127 359.787 460.635 358.754 459.063L261.378 361.686C250.443 350.752 250.443 333.023 261.378 322.088C272.312 311.154 290.041 311.154 300.975 322.088L354.579 375.692Z' /%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='512' height='472' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.20093 110.314C-2.73364 121.248 -2.73364 138.977 8.20093 149.912C19.1355 160.846 36.864 160.846 47.7986 149.912L101.402 96.308V443.711C101.402 459.175 113.938 471.711 129.402 471.711C144.866 471.711 157.402 459.175 157.402 443.711V96.2896L211.024 149.912C221.959 160.846 239.687 160.846 250.622 149.912C261.556 138.977 261.556 121.248 250.622 110.314L153.246 12.9376C152.213 11.3649 151.005 9.87317 149.622 8.49023C144.05 2.91797 136.713 0.185425 129.41 0.292358C122.108 0.185913 114.772 2.91858 109.201 8.49036C107.818 9.87317 106.61 11.3646 105.577 12.9373L8.20093 110.314ZM354.579 375.692V28.2893C354.579 12.8253 367.114 0.289307 382.579 0.289307C398.043 0.289307 410.579 12.8253 410.579 28.2893V375.71L464.201 322.088C475.135 311.154 492.864 311.154 503.799 322.088C514.733 333.023 514.733 350.752 503.799 361.686L406.423 459.062C405.389 460.635 404.181 462.127 402.799 463.51C397.226 469.082 389.889 471.815 382.587 471.708C375.285 471.814 367.949 469.081 362.378 463.51C360.995 462.127 359.787 460.635 358.754 459.063L261.378 361.686C250.443 350.752 250.443 333.023 261.378 322.088C272.312 311.154 290.041 311.154 300.975 322.088L354.579 375.692Z' /%3E%3C/svg%3E");
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  font-size: inherit;
}

// sort asc
.lu-action-sort[data-sort='asc']::before {
  background-color: currentColor;
  content: '11' !important;  /* This is necessary for the icons to have the same size */
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='512' height='472' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M250.622 110.314C261.557 121.248 261.557 138.977 250.622 149.912C239.688 160.846 221.959 160.846 211.024 149.912L157.421 96.308V443.711C157.421 459.175 144.886 471.711 129.421 471.711C113.957 471.711 101.421 459.175 101.421 443.711V96.2895L47.7988 149.912C36.8643 160.846 19.1357 160.846 8.20117 149.912C-2.7334 138.977 -2.7334 121.248 8.20117 110.314L105.577 12.9375C106.61 11.3647 107.818 9.87315 109.201 8.49028C114.773 2.91771 122.111 0.185044 129.414 0.292283C136.716 0.186143 144.051 2.91881 149.622 8.49034C151.005 9.87322 152.213 11.3647 153.246 12.9374L250.622 110.314ZM482 451C498.568 451 512 437.569 512 421C512 404.431 498.568 391 482 391H222C205.432 391 192 404.431 192 421C192 437.569 205.432 451 222 451H482ZM482 265.586C498.568 265.586 512 252.154 512 235.585C512 219.017 498.568 205.585 482 205.585H222C205.432 205.585 192 219.017 192 235.585C192 252.154 205.432 265.586 222 265.586H482ZM482 81C498.568 81 512 67.5685 512 51C512 34.4314 498.568 21 482 21H282C265.432 21 252 34.4314 252 51C252 67.5685 265.432 81 282 81H482Z' /%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='512' height='472' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M250.622 110.314C261.557 121.248 261.557 138.977 250.622 149.912C239.688 160.846 221.959 160.846 211.024 149.912L157.421 96.308V443.711C157.421 459.175 144.886 471.711 129.421 471.711C113.957 471.711 101.421 459.175 101.421 443.711V96.2895L47.7988 149.912C36.8643 160.846 19.1357 160.846 8.20117 149.912C-2.7334 138.977 -2.7334 121.248 8.20117 110.314L105.577 12.9375C106.61 11.3647 107.818 9.87315 109.201 8.49028C114.773 2.91771 122.111 0.185044 129.414 0.292283C136.716 0.186143 144.051 2.91881 149.622 8.49034C151.005 9.87322 152.213 11.3647 153.246 12.9374L250.622 110.314ZM482 451C498.568 451 512 437.569 512 421C512 404.431 498.568 391 482 391H222C205.432 391 192 404.431 192 421C192 437.569 205.432 451 222 451H482ZM482 265.586C498.568 265.586 512 252.154 512 235.585C512 219.017 498.568 205.585 482 205.585H222C205.432 205.585 192 219.017 192 235.585C192 252.154 205.432 265.586 222 265.586H482ZM482 81C498.568 81 512 67.5685 512 51C512 34.4314 498.568 21 482 21H282C265.432 21 252 34.4314 252 51C252 67.5685 265.432 81 282 81H482Z' /%3E%3C/svg%3E");
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  font-size: inherit;
}

// sort desc
.lu-action-sort[data-sort='desc']::before {
  background-color: currentColor;
  content: '11' !important;  /* This is necessary for the icons to have the same size */
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='512' height='472' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M157.421 28.2893C157.421 12.8253 144.885 0.289307 129.421 0.289307C113.957 0.289307 101.421 12.8253 101.421 28.2893V375.711L47.7988 322.088C36.8641 311.154 19.1355 311.154 8.20087 322.088C-2.73383 333.023 -2.73383 350.752 8.20087 361.686L105.576 459.062C106.61 460.635 107.818 462.127 109.201 463.51C114.773 469.082 122.111 471.815 129.414 471.708C136.715 471.814 144.051 469.081 149.622 463.51C151.005 462.127 152.213 460.635 153.246 459.062L250.622 361.686C261.557 350.752 261.557 333.023 250.622 322.088C239.688 311.154 221.959 311.154 211.024 322.088L157.421 375.691V28.2893ZM482 21C498.569 21 512 34.4315 512 51C512 67.5685 498.569 81 482 81H222C205.431 81 192 67.5685 192 51C192 34.4315 205.431 21 222 21H482ZM482 206.415C498.569 206.415 512 219.846 512 236.414C512 252.983 498.569 266.414 482 266.414H222C205.431 266.414 192 252.983 192 236.414C192 219.846 205.431 206.415 222 206.415H482ZM482 391C498.569 391 512 404.431 512 421C512 437.569 498.569 451 482 451H282C265.431 451 252 437.569 252 421C252 404.431 265.431 391 282 391H482Z' /%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='512' height='472' fill='inherit' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M157.421 28.2893C157.421 12.8253 144.885 0.289307 129.421 0.289307C113.957 0.289307 101.421 12.8253 101.421 28.2893V375.711L47.7988 322.088C36.8641 311.154 19.1355 311.154 8.20087 322.088C-2.73383 333.023 -2.73383 350.752 8.20087 361.686L105.576 459.062C106.61 460.635 107.818 462.127 109.201 463.51C114.773 469.082 122.111 471.815 129.414 471.708C136.715 471.814 144.051 469.081 149.622 463.51C151.005 462.127 152.213 460.635 153.246 459.062L250.622 361.686C261.557 350.752 261.557 333.023 250.622 322.088C239.688 311.154 221.959 311.154 211.024 322.088L157.421 375.691V28.2893ZM482 21C498.569 21 512 34.4315 512 51C512 67.5685 498.569 81 482 81H222C205.431 81 192 67.5685 192 51C192 34.4315 205.431 21 222 21H482ZM482 206.415C498.569 206.415 512 219.846 512 236.414C512 252.983 498.569 266.414 482 266.414H222C205.431 266.414 192 252.983 192 236.414C192 219.846 205.431 206.415 222 206.415H482ZM482 391C498.569 391 512 404.431 512 421C512 437.569 498.569 451 482 451H282C265.431 451 252 437.569 252 421C252 404.431 265.431 391 282 391H482Z' /%3E%3C/svg%3E");
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  font-size: inherit;
}

// hide data mapping icon from toolbar when it is inactive, i.e., linear mapping is selected
.lu-toolbar > .lu-action-data-mapping:not([data-active]) {
	display: none;
}

// override the font-size manually (since the SCSS variables above does not work)
.lu-tooltip,
.lu-dialog {
  // adapt LineUp dialogs to Bootstrap v4 dialog style
  padding: 1rem 1rem 0.5rem;
  gap: 10px;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  scrollbar-width: thin;

  strong {
    margin: unset;
  }
}

.lu-dialog {
  input {
    font-size: inherit;
  }

  .lu-dialog-table {
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
  }

  .lu-checkbox {
    display: flex;
    align-items: center;
    height: 1rem;
    gap: 5px;
    margin: unset;
  }

  .lu-checkbox:not(.lu-dialog-filter-table-entry) > span {
    font-size: inherit;
    margin: unset;
    height: 100%;
  }

  .lu-checkbox.lu-dialog-filter-table-entry,
  .lu-checkbox.lu-dialog-color-table-entry {
    height: auto;
    padding: 4px 3px;
    flex: 1;

    &:hover {
      background-color: #f1f2f4;

      .lu-dialog-filter-table-entry-stats {
        color: #8e8e8e;
      }
    }

    & > span {
      display: inline-flex;
      gap: 10px;
    }

    & .lu-dialog-filter-table-entry-label {
      max-width: 25rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  strong:not(:first-of-type) {
    margin-top: 1rem;
  }

}

.lu-dialog-buttons {
  margin-top: 0.5rem;
}

.lu-dialog-button {
  margin: unset;
}

.lu-summary {
  font-size: inherit;

  .lu-checkbox > span {
    font-size: inherit;
    margin: unset;
  }
}

.lu-stats {
  font-size: inherit;

  & > span {
    font-size: inherit;
  }
}

.lu-side-panel-summary {
  font-size: inherit;
  margin: 0.5rem;

  &.lu-renderer-string {
    gap: 10px;
  }
}

.lu-side-panel-ranking,
.lu-group-hierarchy,
.lu-sort-hierarchy,
.lu-sort-groups-hierarchy {
  margin-bottom: 1rem;

  &::before {
    font-size: inherit;
  }
}

.lu-side-panel-entry {
  padding: 0 0 1rem;
}

.lu-side-panel-entry-header {
  font-size: inherit;
  border-top: unset;
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.lu-side-panel-labels {
  padding: 0;
}

.lu-dialog.lu-more-options {
  padding: 0.5rem 0;
  gap: 0;

  & > i.lu-action {
    align-items: baseline;
    padding: 0.25rem 1.5rem 0.25rem 0.75rem;
    gap: 0.5rem;

    & > span {
      margin: unset;
    }
  }
}

.lu-search::before {
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #ced4da;
}

.lu-hierarchy .lu-hierarchy-adder .lu-search::before {
  height: unset;
}

.lu-search-input {
  height: unset;
  border-color: #ced4da;
}

.lu-search-list {
  top: 1.85rem;

  li.lu-search-item > span,
  li.lu-search-group > span {
    padding: 0.5rem;
  }
}

.lu-search-open > input {
  border-radius: 0 4px 0 0;
}

.lu-hierarchy-entry,
.lu-hierarchy-adder {
  padding: 0.3rem 0.5rem;
  align-items: baseline;
  gap: 5px;
  border-top: none;
}

.lu-hierarchy-adder > .lu-search > input {
  height: 1.8rem;
  font-size: inherit;
}

span.lu-mapping-hint {
  font-size: small;
}

.lu-histogram-min-hint,
.lu-histogram-max-hint {
  font-size: inherit;
}

.lu-histogram-max,
.lu-histogram-min {
  &::before {
    bottom: -1.2rem;
    font-size: small;
  }
}

.lu-histogram .lu-checkbox {
  bottom: -3rem;
}

.lu-dialog-rename {
  width: 20rem;
}

.lu-dialog-mapper-warning {
  @extend .alert; // apply base styles of Bootstrap alert
  @extend .alert-warning;
  margin-bottom: 0; // override margin
}
